import _ from 'lodash';
import React from 'react';
import { colors, fontFamilies, Text, themeOptions } from '@commonsku/styles';
import {
  checkUserLicenseInActive,
  checkUserLicensePending,
  checkUserProfileSetup,
} from '../../utils';

const UserEmail = (props) => {
  const { row, style={}, } = props;
  const isProfileSetup = checkUserProfileSetup(row.original);
  const isLicensePending = checkUserLicensePending(row.original);
  const isLicenseInactive = checkUserLicenseInActive(row.original);

  const color = isProfileSetup && (''+row.original.active) === '1'
    ? colors.neutrals['90']
    : colors.neutrals['60'];

  return (
    <div className='user-email-col' style={{...style, overflowWrap: 'break-word', color, ...(isLicensePending ? { width: 320 } : {}), }}>
      {!isLicensePending && <p style={{ color, marginBottom: 0 }}>{row.original.contact_email}</p>}
      {isLicenseInactive && <Text
        style={{ color: colors.errors.main, marginBottom: 0, fontFamily: themeOptions.fontFamilies.regular }}
        data-html={true}
        data-tip={`<span style="font-size: 16px; font-family: ${fontFamilies.regular}">Please check or increase # of licenses</span>`}
        data-for={"user-details-tooltip"}
        data-effect='solid'
      >This license will be removed on {_.get(row.original, ['change_on']) || ''}</Text>}
    </div>
  );
};

export default UserEmail;
