import React from 'react';
import { TabBar, Tab } from '@commonsku/styles';
import { useHasCapabilities } from "../../hooks";

export default function AdminSettingTopTabs({ activeTab = null, samePageTabs = [], setActiveTab = null }) {
    const isFull = useHasCapabilities(['FEATURE-FULL']);
    const isDistributor = useHasCapabilities(['COMPANY-TYPE-DISTRIBUTOR']);

    const tabs = [
        { title: 'My Settings', key: 'my-settings', link: '/admin_settings_new.php?tab=my-settings' },
        { title: 'Manage Users', key: 'users', link: '/admin_users_new.php' },
        { title: 'Company Settings', key: 'settings', link: '/admin_settings_new.php' },
        { title: 'Billing', key: 'billing', link: '/admin_settings_new.php?tab=billing' },
    ];

    if (isDistributor && !isFull) {
        tabs.push({ title: 'Licenses', key: 'licenses', link: '/admin_settings_new.php?tab=licenses' })
    }

    return (
        <TabBar style={{ marginBottom: '1rem' }}>
            {tabs.map((tab, index) => <Tab
                style={activeTab === tab.key ? { borderBottomColor: '#EB1D6E' } : null}
                key={'tabs_' + index} size={tabs.length}
                selected={activeTab === tab.key}
                onClick={() => {
                    if (samePageTabs.indexOf(tab.key) !== -1 && setActiveTab) {
                        setActiveTab(tab.key);
                    } else {
                        window.location.href = `https://${window.location.hostname}${tab.link}`;
                    }
                }}>
                {tab.title}
            </Tab>)}
        </TabBar>
    );
}
