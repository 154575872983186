import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { Col, colors, Dropdown, fontFamilies, Input, LabeledCheckbox, LabeledIconInput, Row, SearchIcon, Tab, TabBar, Text } from '@commonsku/styles';
import { createLoadUsers, createLoadFeatures } from '../../actions';
import { useHasCapabilities, useIdentity } from '../../hooks';
import { getActiveUsersList } from '../../selectors/users';
import { getIdentityUtils, oauth, parseRestBoolean } from '../../utils';
import UsersTable from './users-table';
import TableFooter from './users-table/TableFooter';
import CreateUserPopup from './popups/CreateUserPopup';
import ReactTooltip from '../helpers/ReactTooltip';
import { fetchCompanyEmailDomains } from '../../redux/company_email_domains';
import {
  checkCanUpgradeUser,
  fetchStripeInfo,
  selectStripeInfo,
  selectStripePendingChange,
  selectStripeUnusedLicensesQty,
} from '../../redux/stripe';
import ManageGroupsPopup from '../groups-management/ManageGroupsPopup';
import ManagePermissionsPopup from '../permissions-management/ManagePermissionsPopup';
import { rebuildTooltip } from '../helpers';
import { checkUserProfileSetup, USER_LICENSE_TYPE } from './utils';
import ReassignClientsPopup from './popups/ReassignClientsPopup';
import AdminSettingTopTabs from '../admin-new/AdminSettingTopTabs';
import DashboardPageTitle from '../dashboard/DashboardPageTitle';

const POPUP_TYPE = {
  ADD_USER: 'ADD_USER',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_PERMISSIONS: 'MANAGE_PERMISSIONS',
  REASSIGN_CLIENTS: 'REASSIGN_CLIENTS',
};

const TAB_TYPE = {
  USERS: 'USERS',
};

const PERMISSIONS = {
  MANAGE_PERMISSIONS: ['MODIFY-PERMISSIONS'],
  MANAGE_GROUPS: ['MODIFY-SETTINGS'],
  ADD_USER: ['MODIFY-USER'],
};

/**
 * usersFilter
 * @param {object} u user object
 * @param {{ query: string; isProfileSetup: null | boolean; isVerified: null | boolean; }} filters search
 * @param {boolean} isTenant
 * @param {boolean} isSupplier
 * @returns {boolean}
 */
const usersFilter = (u, filters, isTenant, isSupplier) => {
  if (!filters.query && filters.isProfileSetup === null && filters.isVerified === null) {
    return true;
  }
  const query = filters.query.toLowerCase();
  const isProfileSetup = checkUserProfileSetup(u);
  const isVerified = (
    isSupplier || (
      isTenant &&
      (parseRestBoolean(u.email_verified) || u.domain_verification_status === 'Success')
    )
  );
  return (u.contact_full_name || '').toLowerCase().includes(query)
    || (u.contact_email || '').toLowerCase().includes(query)
    || (u.role_name || '').toLowerCase().includes(query)
    || (u.feature_name || '').toLowerCase().includes(query)
    || ((query === 'verified' || filters.isVerified === true) && isVerified)
    || ((query === 'unverified' || filters.isVerified === false) && !isVerified)
    || ((query === 'profile setup' || filters.isProfileSetup === true) && isProfileSetup)
    || ((query === 'not activated' || filters.isProfileSetup === false) && !isProfileSetup);
};

export default function UserManagement() {
  const dispatch = useDispatch();
  const usersList = useSelector(s => {
    const featureIds = _.map(
      _.filter(Object.values(s.entities.features), v => ['CONNECTED', 'INSIGHTS', 'FULL', 'COMMUNITYBASIC', 'COMMUNITYPLUS', 'CONNECTEDBASIC', 'CONNECTEDPLUS'].includes(v.feature_name)),
      v => v.feature_id
    );
    return _.orderBy(
      getActiveUsersList(s).filter(v => ('' + v.active) === '1'),
      [
        v => v.active,
        v => featureIds.includes(v.feature_id) ? 1 : 0,
        v => v.date_created
      ],
      ['asc', 'asc', 'desc']
    );
  });
  const usersLoading = useSelector(s => s.display.loading.users);
  const canUpgradeUser = useSelector(checkCanUpgradeUser);
  const stripeInfo = useSelector(selectStripeInfo);
  const pendingChange = useSelector(selectStripePendingChange);
  const unusedLicensesQty = useSelector(selectStripeUnusedLicensesQty);
  const hasUserGroups = useHasCapabilities(['HAS-USER-GROUPS']);
  const identity = useIdentity();
  const [selectedTab, setSelectedTab] = useState(TAB_TYPE.USERS);
  const [showPopup, setShowPopup] = useState(false);
  const [filters, setFilters] = useState({
    query: '',
    isVerified: null, // null|bool
    isProfileSetup: null, // null|bool
  });

  const { hasCapabilities, isTenant, isSupplier, isTeam } = getIdentityUtils(identity);
  const canModifyUsers = hasCapabilities(PERMISSIONS.ADD_USER);
  const canModifyPermissions = hasCapabilities(PERMISSIONS.MANAGE_PERMISSIONS);
  const canModifySettings = hasCapabilities(PERMISSIONS.MANAGE_GROUPS);
  const canAddUsers = (isTeam() || isSupplier() || (!isTeam() && isTenant() && canUpgradeUser)) && canModifyUsers;
  const isFull = isTenant() && hasCapabilities('FEATURE-FULL');

  const users = useMemo(() => {
    const is_tenant = isTenant(),
          is_supplier = isSupplier();
    const usersListFiltered = usersList.filter(u => usersFilter(u, filters, is_tenant, is_supplier));
    if (!is_tenant) {
      return usersListFiltered;
    }

    const unusedLicenses = (
      unusedLicensesQty > 0 ? (new Array(unusedLicensesQty)).fill(1) : []
    ).map((_v, i, arr) => {
      const pendingQty = stripeInfo.quantity - _.get(pendingChange, ['quantity'], stripeInfo.quantity);
      let showQtyChange = false;
      let changeOn = null;
      let licensesUsed = parseInt(stripeInfo.activeUsers) + (arr.length - i);
      let totalLicenses = stripeInfo.quantity;
      if (pendingQty > 0) {
        showQtyChange = i + 1 <= pendingQty;
        changeOn = moment(pendingChange.charge_date).format('MMM DD, YYYY');
      }
      return {
        user_id: showQtyChange
          ? USER_LICENSE_TYPE.LICENSE_UNAVAILABLE
          : USER_LICENSE_TYPE.LICENSE_AVAILABLE,
        change_on: changeOn,
        licenses_used: licensesUsed,
        total_licenses: totalLicenses,
        date_created: '2010-01-0' + (i + 1),
        contact_image_id: '',
        contact_full_name: (i + 1) + 'license-change',
        role_name: (i + 1) + 'role-license-change',
        feature_name: (i + 1) + 'feature-license-change',
        contact_email: (i + 1) + 'email-license-change',
        email_verified: 0,
      };
    });

    return unusedLicenses.concat(usersListFiltered);
  }, [
    usersList,
    unusedLicensesQty,
    stripeInfo,
    pendingChange,
    isTenant,
    isSupplier,
    filters,
  ]);

  useEffect(() => {
    Promise.all([
      dispatch(createLoadUsers(
        identity.company_id,
        identity.company_type,
        true,
        { include_inactive: true, with_user_profile_setup: true, },
        false
      )),
      dispatch(createLoadFeatures(identity.company_type, false)),
      dispatch(fetchStripeInfo({}, false)),
      dispatch(fetchCompanyEmailDomains(identity.company_id, identity.company_type)),
    ]);
    rebuildTooltip();
  }, [dispatch, identity]);

  useEffect(() => {
    rebuildTooltip();
  }, [canAddUsers, showPopup, selectedTab, canUpgradeUser, isTeam, isTenant]);

  const [activeTab, setActiveTab] = useState('users');

  const onChangeQuery = useCallback((q = '') => setFilters(s => ({ ...s, query: q })), []);
  const onClose = useCallback(() => setShowPopup(false), []);

  const showAddUserPopup = useCallback(() => {
    if (canAddUsers) {
      setShowPopup(POPUP_TYPE.ADD_USER);
    } else {
      ReactTooltip.hide();
    }
  }, [canAddUsers]);

  const actionItems = useMemo(() => {
    const result = [
      {
        id: 'add-user-btn',
        content: (
          <span
            style={{ width: '100%' }}
          >{isSupplier() ? 'Invite Coworker' : 'Add user'}</span>
        ),
        onClick: showAddUserPopup,
        disabled: !canAddUsers,
        props: {
          'data-html': true,
          'data-tip': `<span
            style="padding: 15px; font-size: 16px; font-family: ${fontFamilies.demibold}; color: ${colors.neutrals['70']};"
          >All licenses are in use. Please purchase or transfer a license to add a new user.</span>`,
          'data-effect': 'solid',
          'data-for': (!isTeam() && isTenant() && !canUpgradeUser) ? "add-user-tooltip" : '',
          style: canAddUsers ? {} : {
            color: colors.neutrals['70'],
            background: colors.neutrals['50'],
          },
        },
      },
      { id: 'set-permissions-btn', content: 'Set permissions', disabled: !canModifyPermissions, onClick: () => setShowPopup(POPUP_TYPE.MANAGE_PERMISSIONS) },
    ];

    if (isTenant()) {
      if (hasUserGroups) {
        result.push({ id: 'manage-groups-btn', content: 'Manage groups', disabled: !canModifySettings, onClick: () => setShowPopup(POPUP_TYPE.MANAGE_GROUPS) });
      }
      result.push({ id: 'reassign-clients-btn', content: 'Reassign clients', onClick: () => setShowPopup(POPUP_TYPE.REASSIGN_CLIENTS) });
    }
    return result;
  }, [
    showAddUserPopup,
    canModifyPermissions,
    canModifySettings,
    canUpgradeUser,
    isTenant,
    isSupplier,
    isTeam,
    canAddUsers,
  ]);

  return (
    <Row style={{ paddingBottom: 10, }} id='user-management-wrapper'>
      <ToastContainer />
      {!showPopup && <ReactTooltip id="add-user-tooltip" />}
      {showPopup === POPUP_TYPE.ADD_USER
        && <CreateUserPopup onClose={onClose} />}
      {showPopup === POPUP_TYPE.MANAGE_GROUPS
        && <ManageGroupsPopup onClose={onClose} />}
      {showPopup === POPUP_TYPE.MANAGE_PERMISSIONS
        && <ManagePermissionsPopup onClose={onClose} />}
      {showPopup === POPUP_TYPE.REASSIGN_CLIENTS
        && <ReassignClientsPopup onClose={onClose} />}

      <Col xs><div style={{ paddingLeft: '0.75rem', paddingTop: '1rem' }}><DashboardPageTitle title='Settings' /></div></Col>
      <Col xs sm={7}>
        <AdminSettingTopTabs
          activeTab={activeTab}
          samePageTabs={['users']}
          setActiveTab={setActiveTab}
        />
      </Col>
      <Col xs sm={5} padded smStyle={"text-align: right;"}>
        <div style={{ display: 'inline-block', marginRight: 10, verticalAlign: 'middle', width: 'calc(100% - 175px)', minWidth: 200, }}>
          <LabeledIconInput
            Icon={<SearchIcon />}
            label=''
            placeholder='Search for user'
            value={filters.query}
            onChange={e => onChangeQuery(e.target.value)}
            iconLabelStyles={{ background: 'white', }}
            iconColor={colors.neutrals[50]}
          />
        </div>
        <Dropdown
          id='actions-btn'
          size='medium'
          bordered
          width={250}
          sm={'width: 140px; margin-top: 10px;'}
          style={{ marginRight: '10px', zIndex: 105, verticalAlign: 'middle', float: 'right' }}
          buttonVariant='cta'
          hideOnMouseLeave={false}
          items={actionItems}
          onToggleMenu={(val) => {
            rebuildTooltip();
          }}
        />
      </Col>
      {isSupplier() && <Col xs padded>
        <Text style={{
          fontFamily: fontFamilies.regular,
        }}>
          A <span style={{ padding: '0 5px 0 5px', }} dangerouslySetInnerHTML={{ __html: '&star;' }} /> beside a user's role indicates they are a collaborate champion who will receive an email notification for any collaborate requests.
        </Text>
      </Col>}
      <Col xs>
        <UsersTable
          loading={usersLoading}
          data={users}
          showAddUserPopup={showAddUserPopup}
        />
      </Col>
      {(isTenant() && !isTeam() && isFull) && <Col xs style={{ paddingRight: 7, }}>
        <TableFooter
          totalUsers={users.length}
          showAddUserPopup={showAddUserPopup}
          hideLoading={usersLoading}
        />
      </Col>}
    </Row>
  );
}
