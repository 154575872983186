import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { getIdentityUtils, oauth } from '../utils';

const initialState = {
  data: {
    customer: null,
    item: null,
    plan: null,
    quantity: 0,
    initialQuantity: 0,
    activeUsers: 0,
    hasLegacyAccount: false,
    pendingChange: null,
  },
  temp: {
    message: '',
  },
  loading: {},
  errors: '',
};

const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setStripeInfo(state, action) {
      state.data = action.payload;
    },
    updateStripeInfo(state, action) {
      const { stripe_info, active_users }  = action.payload || {};
      const cus = _.get(stripe_info, 'stripe_customer');
      const item = _.get(stripe_info, 'stripe_subscription_item');
      if (!cus) {
        state.errors = 'No payment information found.';
        state.loading = false;
        state.temp.message = '';
        return state;
      }
      if (!item) {
        state.errors = 'Unable to retrieve plans.';
        state.temp.message = '';
      }
      const pendingChange = getPendingQtyChange(stripe_info);
      state.data.pendingChange = pendingChange;
      state.data.customer = cus;
      state.data.item = item;
      state.data.plan = _.get(item, 'price');
      state.data.quantity = _.toInteger(_.get(item, 'quantity', 0));
      state.data.initialQuantity = _.toInteger(_.get(item, 'quantity', 0));
      state.loading = false;

      state.data.activeUsers = active_users || 0;
      state.data.hasLegacyAccount = !!stripe_info.stripe_has_legacy_account;

      return state;
    },
    updateQuantity(state, action) {
      const q = _.toInteger(action.payload.quantity);
      state.errors = '';
      state.temp.message = action.payload.success || '';
      state.data.pendingChange = null;
      if (q >= state.data.quantity) {
        state.data.initialQuantity = q;
        state.data.quantity = q;
      }
    },
    updatePendingChange(state, action) {
      if (!state.data.pendingChange) {
        state.data.pendingChange = {
          quantity: action.payload.quantity,
          charge_date: action.payload.charge_date,
        };
      } else {
        state.data.pendingChange.quantity = action.payload.quantity;
      }
    },
    setCustomer(state, action) {
      state.data.customer = action.payload;
    },
    setItem(state, action) {
      state.data.item = action.payload;
    },
    setPlan(state, action) {
      state.data.plan = action.payload;
    },
    setInitialQuantity(state, action) {
      state.data.initialQuantity = action.payload;
    },
    setQuantity(state, action) {
      state.data.quantity = action.payload;
    },
    setPendingChange(state, action) {
      state.data.pendingChange = action.payload;
    },
    setActiveUsers(state, action) {
      state.data.activeUsers = action.payload;
    },
    incrementActiveUsers(state, action) {
      state.data.activeUsers += (action.payload || 1);
    },
    decrementActiveUsers(state, action) {
      state.data.activeUsers -= (action.payload || 1);
    },
    setTemp(state, action) {
      state.temp = action.payload;
    },
    updateTemp(state, action) {
      state.temp = {
        ...state.temp,
        ...action.payload,
      };
    },
    setError(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const fetchStripeInfo = (params={}, addToQueue = true) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const { json } = await oauth('GET', 'stripe', params, null, null, null, addToQueue);
    if (_.get(json, ['error'])) {
      dispatch(setError(getErrorMessageFromError(json.error, 'Cannot get stripe info.')));
      dispatch(setLoading(false));
      dispatch(updateTemp({ message: '' }));
      return;
    }

    onFetchStripeInfo(
      _.get(json, ['stripe_info']) || {},
      _.get(json, ['active_users']) || 0,
      dispatch
    );
  } catch (error) {
    console.error('ERROR|fetchStripeInfo| ', error);
    dispatch(setError(getErrorMessageFromError(error, 'No payment information found.')));
    dispatch(updateTemp({ message: '' }));
  }
  dispatch(setLoading(false));
};

export const updateStripeQuantity = (
  quantity,
) => async (dispatch, getState) => {
  if (!isValidQuantity(quantity)) {
    return false;
  }
  const state = getState().stripe;
  const prevQuantity = _.max([2, state.data.quantity]);
  const minimum = _.max([2, state.data.activeUsers]);

  if (quantity < minimum) {
    dispatch(setError(`The minimum quantity you must order is ${minimum} licenses.`));
    dispatch(setLoading(false));
    dispatch(updateTemp({ message: '' }));
    return false;
  }

  dispatch(setLoading(true));
  try {
    const { json } = await oauth('POST', 'stripe', { action: 'change-plan', quantity });
    if (_.get(json, ['error'])) {
      dispatch(setError(getErrorMessageFromError(json.error, 'Cannot get stripe info.')));
      dispatch(setLoading(false));
      dispatch(updateTemp({ message: '' }));
      return;
    }

    if (_.get(json, ['stripe_info'])) {
      onFetchStripeInfo(
        _.get(json, ['stripe_info']),
        _.get(json, ['active_users']) || 0,
        dispatch
      );
    } else {
      if (prevQuantity > quantity) {
        await dispatch(fetchStripeInfo());
      } else {
        dispatch(updateQuantity(json));
      }
    }
  } catch (error) {
    console.error('ERROR|updateStripeQuantity| ', error);
    dispatch(setError(getErrorMessageFromError(error, 'No payment information found.')));
    dispatch(updateTemp({ message: '' }));
  }
  dispatch(setLoading(false));
};

export const releaseChanges = () => async (dispatch, getState) => {
  try {
    const { json } = oauth('POST', 'stripe', { action: 'release-qty-pending-schedule' });
    if (json.error) {
      return;
    }
    dispatch(setError(''));
    dispatch(setPendingChange(null));
  } catch (error) {
    dispatch(setError(error.error));
  }
  dispatch(setLoading(false));
};

// utils
export const isValidQuantity = value => _.toString(_.toInteger(value)) === _.toString(value);
function getErrorMessageFromError(error, defaultMsg = "Unable to perform this action") {
  if (typeof error === 'string') { return error; }
  if (_.get(error, 'message', false)) {
    return _.get(error, 'message', false);
  }
  if (_.get(error, 'detail', false)) {
    return _.get(error, 'detail', false);
  }
  return defaultMsg;
}
export const getPendingQtyChange = (stripe_info) => {
  const pendingQtyChange = _.get(stripe_info, 'stripe_pending_quantity_change');
  const pendingQtyChangePhase = _.last(_.get(pendingQtyChange, ['schedule', 'phases'], []));
  if(pendingQtyChangePhase) {
    const phaseItems = pendingQtyChangePhase.items || pendingQtyChangePhase.plans;
    let chargeDate = _.get(pendingQtyChange, ['schedule', 'current_phase', 'end_date']);
    if (chargeDate) {
      chargeDate = chargeDate * 1000;
    } else {
      chargeDate = _.get(pendingQtyChangePhase, ['start_date'], null);
    }
    return {
      quantity: _.get(_.first(phaseItems), ['quantity'], 0),
      charge_date: chargeDate,
    };
  }
  return null;
};

export function getQtyWithPendingChange(state) {
  let quantity = _.toInteger(_.get(state, ['quantity']) || 0);
  const pendingChange = _.get(state, ['pendingChange']);
  const pendingQty = _.toInteger(_.get(state, ['pendingChange', 'quantity']) || 0);
  if (pendingChange && pendingQty > 1) {
    quantity = pendingQty;
  }

  return quantity;
};

const getStripe = state => state.stripe;
export const selectStripeInfo = createSelector(
  getStripe,
  s => s.data,
);
export const selectStripeUnusedLicensesQty = createSelector(
  selectStripeInfo,
  s => _.max([0, parseInt(s.quantity) - parseInt(s.activeUsers)]),
);
export const selectStripeCustomer = createSelector(
  selectStripeInfo,
  s => _.get(s, ['customer']),
);
export const selectStripePlanId = createSelector(
  selectStripeInfo,
  s => _.get(s, ['plan', 'id']),
);
export const selectStripePlanInterval = createSelector(
  selectStripeInfo,
  s => s?.customer?.subscriptions?.data[0]?.items?.data[0]?.price?.recurring.interval
);
export const selectStripeQuantity = createSelector(
  selectStripeInfo,
  s => _.get(s, ['quantity']),
);
export const selectStripeInitialQuantity = createSelector(
  selectStripeInfo,
  s => _.get(s, ['initialQuantity']),
);
export const selectStripePendingChange = createSelector(
  selectStripeInfo,
  s => s.pendingChange,
);
export const selectStripeActiveUsers = createSelector(
  selectStripeInfo,
  s => _.get(s, ['activeUsers']),
);
export const selectStripeCalculatedQuantity = createSelector(
  selectStripeInfo,
  getQtyWithPendingChange,
);
export const selectStripeError = createSelector(
  getStripe,
  s => s.errors,
);
export const selectStripeLoading = createSelector(
  getStripe,
  s => s.loading,
);
export const selectStripeTemp = createSelector(
  getStripe,
  s => s.temp,
);
export const selectStripeTempMessage = createSelector(
  getStripe,
  s => _.get(s, ['temp', 'message']) || '',
);

export const checkCanUpgradeUser = createSelector(
  [selectStripeInfo, s => s.identity],
  (s, identity) => {
    const { isTeam, isSupplier } = getIdentityUtils(identity);
    if(isTeam() || isSupplier()) { return true; }

    const activeUsers = _.toInteger(_.get(s, ['activeUsers']) || 0);
    const quantity = getQtyWithPendingChange(s);

    return activeUsers < quantity;
  },
);

function onFetchStripeInfo(stripeInfo, activeUsers, dispatch) {
  const cus = _.get(stripeInfo, 'stripe_customer');
  const item = _.get(stripeInfo, 'stripe_subscription_item');
  if (!cus) {
    dispatch(setError('No payment information found.'));
    dispatch(setLoading(false));
    dispatch(updateTemp({ message: '' }));
    return;
  }
  const pendingChange = getPendingQtyChange(stripeInfo);
  if (!item) {
    dispatch(setError('Unable to retrieve plans.'));
  }
  dispatch(setActiveUsers(_.toInteger(activeUsers)));
  dispatch(setCustomer(cus));
  dispatch(setPendingChange(pendingChange));
  dispatch(setPlan(_.get(item, 'price')));
  dispatch(setQuantity(_.toInteger(_.get(item, 'quantity', 0))));
  dispatch(setInitialQuantity(_.toInteger(_.get(item, 'quantity', 0))));
  dispatch(setLoading(false));
}

export const {
  updateStripeInfo,
  updateQuantity,
  setStripeInfo,
  setCustomer,
  setInitialQuantity,
  setItem,
  setPlan,
  setQuantity,
  setPendingChange,
  setActiveUsers,
  incrementActiveUsers,
  decrementActiveUsers,
  setTemp,
  updateTemp,
  setError,
  setLoading,
} = slice.actions;

export default slice.reducer;
