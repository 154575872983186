import React from 'react';
import { colors, fontFamilies, LightIndicator, } from '@commonsku/styles';
import { checkUserLicenseUnused, checkUserLicenseInActive, checkUserProfileSetup, checkUser2Fa } from '../../utils';
import { useSelector } from 'react-redux';
import { getSocialFeatureId, getFeatureIdByName } from '../../../../selectors';
import { useIdentity } from '../../../../hooks';
import { getIdentityUtils, parseRestBoolean } from '../../../../utils';

const emailUnverifiedTooltip = `
<p style="font-size: 16px; margin: 2px; font-family: ${fontFamilies.regular}">
  This user has not initiated their <a style="color: #fff; text-decoration: underline;" target="_blank" href="https://help.commonsku.com/knowledge/email-verification#verification-email">
    email verification.
  </a>
</p>
`;

const UserVerified = (props) => {
  const { row, style={}, } = props;
  const socialFeatureId = useSelector(getSocialFeatureId);
  const dormantFeatureId = useSelector(s => getFeatureIdByName(s, 'DORMANT'));
  const identity = useIdentity();
  const { isSupplier } = getIdentityUtils(identity);

  const has2Fa = checkUser2Fa(row.original);
  const isVerified = isSupplier() || (
    (parseRestBoolean(row.original.email_verified) || row.original.domain_verification_status === 'Success') &&
    !isSupplier()
  );
  const featureId = row.original.feature_id;
  const isLicenseUnused = checkUserLicenseUnused(row.original);
  const isLicenseInactive = checkUserLicenseInActive(row.original);
  const isProfileSetup = checkUserProfileSetup(row.original);
  const color = isProfileSetup && (''+row.original.active) === '1'
    ? colors.neutrals['90']
    : colors.neutrals['60'];

  const indicatorData = React.useMemo(() => {
    if (isLicenseUnused) {
      return {
        type: 'UNUSED_LICENSE',
        value: 'Unused License',
        on: false,
        hasTooltip: true,
        tooltipText: `<span style="font-size: 16px; font-family: ${fontFamilies.regular}">Click add user to assign this license to a user profile.</span>`,
      };
    } else if (!isProfileSetup) {
      return {
        type: 'PROFILE_SETUP',
        value: 'Not activated',
        on: false,
        hasTooltip: true,
        tooltipText: `<span style="font-size: 16px; font-family: ${fontFamilies.regular}">Awaiting profile activation (link sent via email).</span>`,
      };
    } else if (featureId === socialFeatureId || featureId === dormantFeatureId) {
      return {
        type: 'FEATURE',
        value: featureId === dormantFeatureId ? 'Dormant' : 'Social',
        on: false,
        hasTooltip: true,
        tooltipText: `<span style="font-size: 16px; font-family: ${fontFamilies.regular}">Add a license to grant this user access to commonsku.</span>`,
      };
    } else if (isVerified) {
      return {
        type: 'VERIFIED',
        value: 'Verified',
        on: true,
        hasTooltip: true,
        tooltipText: `<span style="font-size: 16px; font-family: ${fontFamilies.regular}">This user is verified${isSupplier() ? '.' : ' to send emails from commonsku.'}</span>`,
      };
    }
    return {
      type: 'UNVERIFIED',
      value: 'Unverified',
      on: false,
      hasTooltip: true,
      tooltipText: emailUnverifiedTooltip,
    };
  }, [
    featureId,
    socialFeatureId,
    dormantFeatureId,
    isLicenseUnused,
    isProfileSetup,
    isVerified,
    isSupplier,
  ]);

  return (
    <div className='user-verification-col' style={{
      ...style,
      overflowWrap: 'break-word',
      color: color,
      ...(isLicenseUnused ? { width: 140 }: {}),
    }}>
      {!isLicenseInactive && <LightIndicator
        style={{ margin: 0, }}
        on={indicatorData.on}
        name={indicatorData.value}
        textProps={indicatorData.hasTooltip ? {
          'data-html': true,
          'data-tip': indicatorData.tooltipText || '',
          'data-for': "user-details-tooltip",
          'data-delay-hide': '600',
          'data-effect': 'solid',
        } : {}}
      />}
      {has2Fa && <LightIndicator
        style={{ margin: 0, }}
        on={true}
        name={'2FA enabled'}
      />}
    </div>
  );
};

export default UserVerified;
