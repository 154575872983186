import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import {
  Col,
  Loading,
  Row,
  VirtualTableStyles,
  VirtualTable,
  colors,
} from '@commonsku/styles';
import { rebuildTooltip } from '../../helpers';
import { useIdentity } from '../../../hooks';
import useWindowSize from '../../../hooks/useWindowSize';
import UserAvatar from './cols/UserAvatar';
import UserEditButton from './cols/UserEditButton';
import UserEmail from './cols/UserEmail';
import UserName from './cols/UserName';
import UserRole from './cols/UserRole';
import UserFeature from './cols/UserFeature';
import UserVerified from './cols/UserVerified';
import EditUserPopup from '../popups/EditUserPopup';
import { createLoadCompanyRoles } from '../../../actions';
import { checkUserLicensePending, checkUserLicenseUnused } from '../utils';
import ReactTooltip from '../../helpers/ReactTooltip';
import { parseRestBoolean } from '../../../utils';

const EmptyComponent = () => null;

const IdField = 'user_id';
const UsersTable = (props) => {
  const {
    data = [],
    loading,
    handleUpdateRow,
    showAddUserPopup,
    defaultSort = { id: 'date_created', desc: true },
  } = props;

  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const identity = useIdentity();

  const [skipPageReset, setSkipPageReset] = useState(false);
  const [editUser, setEditUser] = useState(false);

  useEffect(() => {
    if (!identity.company_id) { return; }
    dispatch(createLoadCompanyRoles(identity.company_id));
  }, [dispatch, identity.company_id]);

  useEffect(() => {
    rebuildTooltip();
    setSkipPageReset(false);
  }, [data]);

  const onExpand = useCallback(({row, resetList, toggleAllRowsExpanded}) => {
    toggleAllRowsExpanded && toggleAllRowsExpanded(false);
    if (!row.isExpanded) {
      setEditUser({ row, resetList, toggleAllRowsExpanded });
      row.toggleRowExpanded();
    } else {
      setEditUser(false);
    }
    resetList && resetList();
    rebuildTooltip();
  }, []);

  const onClickRow = useCallback(
    (row, _index, { resetList, toggleAllRowsExpanded, cell }) => {
      if (checkUserLicenseUnused(row)) {
        rebuildTooltip();
        showAddUserPopup();
        return;
      } else if (checkUserLicensePending(row)) {
        return;
      }

      onExpand({ row: cell.row, resetList, toggleAllRowsExpanded });
    }, [onExpand, showAddUserPopup]
  );

  const columns = useMemo(() => ([
    {
      Header: EmptyComponent,
      accessor: v => `${v.user_image_paths}`,
      Cell: UserAvatar,
      sticky: 'left',
      noDrag: true,
      minWidth: 60,
      width: 60,
      maxWidth: 500,
      id: 'contact_image_id',
      canSort: false,
    },
    {
      Header: EmptyComponent,
      accessor: p => `${p.contact_full_name}`,
      sticky: 'left',
      Cell: UserName,
      noDrag: true,
      minWidth: 200,
      width: 200,
      maxWidth: 500,
      id: 'contact_full_name',
      canSort: false,
    },
    {
      Header: EmptyComponent,
      accessor: v => `${v.role_name}`,
      Cell: UserRole,
      sticky: 'left',
      noDrag: true,
      minWidth: 120,
      width: 120,
      maxWidth: 500,
      id: 'role_name',
      canSort: false,
    },
    {
      Header: EmptyComponent,
      accessor: v => `${v.contact_email}`,
      Cell: UserEmail,
      sticky: 'left',
      noDrag: true,
      minWidth: 250,
      width: 250,
      maxWidth: 500,
      id: 'contact_email',
      canSort: false,
    },
    {
      Header: EmptyComponent,
      accessor: v => (parseRestBoolean(v.email_verified) || v.domain_verification_status === 'Success') ? '1' : '0',
      Cell: UserVerified,
      sticky: 'left',
      noDrag: true,
      minWidth: 118,
      width: 120,
      maxWidth: 500,
      id: 'email_verified',
      canSort: false,
    },
    {
      Header: EmptyComponent,
      accessor: v => `edit-user-${v.user_id}`,
      Cell: UserEditButton,
      sticky: 'left',
      noDrag: true,
      minWidth: 60,
      width: 65,
      maxWidth: 500,
      id: IdField,
      canSort: false,
      toggleEditing: onExpand,
    },
  ]), [onExpand]);

  const tableHeight = useMemo(() => {
    if (data.length < 7) {
      return 85 * data.length;
    }
    return getTableHeight(windowSize[1]);
  }, [windowSize, data]);

  return (
    <>
      {editUser && <EditUserPopup {...editUser} onClose={onExpand} />}
      {!editUser && <ReactTooltip id="user-details-tooltip" isCapture={true} />}
      <VirtualTableStyles
        id='users-table-wrapper'
        style={loading ? {display: 'none'} : {  }}
        tableHeight={tableHeight}
        rowClickable
      >
        <VirtualTable
          hideHeader
          id='users-table-list'
          className='user-management-list'
          columns={columns}
          data={data}
          defaultSort={defaultSort}
          onScroll={rebuildTooltip}
          onUpdateData={handleUpdateRow}
          useTableProps={{
            autoResetPage: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            autoResetExpanded: false,
          }}
          height={tableHeight}
          itemSize={() => 80}
          onClickRow={onClickRow}
          onResize={rebuildTooltip}
          rowGroupStyles={({ style }) => {
            const styles = {top: `${parseFloat(style.top) + 10}px`};
            return styles;
          }}
          rowStyles={({ row }) => {
            const styles = {};
            if (row.isExpanded) {
              styles['background'] = '#fff';
              styles['borderBottomRightRadius'] = 0;
              styles['borderBottomLeftRadius'] = 0;
            } else if (checkUserLicensePending(row.original)) {
              styles['border'] = `1px dashed ${colors.teal['65']}`;
              styles['cursor'] = 'pointer';
              styles['padding'] = 7;
            }
            return styles;
          }}
        />
        <Tooltip id={"user-avatar"} delayShow={100} />
      </VirtualTableStyles>
      {loading ? <Row>
        <Col xs style={{ alignSelf: "100%", textAlign: 'center' }}>
          <Loading />
        </Col>
      </Row> : null}
    </>
  );
};

function getTableHeight(winHeight) {
  if (winHeight < 251) {
    return 100;
  } else if (winHeight < 351) {
    return 150;
  } else if (winHeight < 540) {
    return winHeight - 280;
  } else if (winHeight < 700) {
    return winHeight - 350 > 450 ? 450 : winHeight - 350;
  } else if (winHeight < 850) {
    return winHeight - 300 > 450 ? 450 : winHeight - 300;
  }

  return winHeight - 400;
}

export default UsersTable;
