import { toInteger } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, } from 'react-redux';
import { Col, colors, Link, Loading, Row, Text, themeOptions } from '@commonsku/styles';
import { useIdentity } from '../../../hooks';
import UpdateLicensePopup from '../popups/UpdateLicensePopup';
import {
  selectStripeCalculatedQuantity,
  selectStripeError,
  selectStripeInfo,
  selectStripeLoading,
  selectStripePendingChange,
} from '../../../redux/stripe';

const POPUP_TYPES = {
  UPDATE_LICENSES: 'UPDATE_LICENSES',
  ADD_USER: 'ADD_USER',
};

export const TableFooter = (props) => {
  const {
    totalUsers = 0,
    hideLoading = false,
  } = props;

  const identity = useIdentity();
  const stripeInfo = useSelector(selectStripeInfo);
  const qty = useSelector(selectStripeCalculatedQuantity);
  const pendingChange = useSelector(selectStripePendingChange);
  const stripeLoading = useSelector(selectStripeLoading);
  const stripeError = useSelector(selectStripeError);

  const [showPopup, setShowPopup] = useState(false);

  return (<>
    {showPopup === POPUP_TYPES.UPDATE_LICENSES && <UpdateLicensePopup
      onClose={() => setShowPopup(false)}
      minQuantity={toInteger(stripeInfo.activeUsers)}
    />}

    <Row style={{ zIndex: 9, marginTop: 10, paddingRight: totalUsers > 5 ? 22 : 8, position: 'sticky', }}>
      <Col xs style={{ ...(!stripeLoading || hideLoading ? { display: 'none' } : {}), textAlign: 'center', }}>
        <Loading />
      </Col>
      {'SUPPLIER' !== identity.company_type && <Col xs center style={{ padding: 20, ...(stripeLoading ? { display: 'none' } : {}) }}>
        {!stripeError ? <>
          <Link
            style={{ fontWeight: 'bold', fontSize: 20, paddingLeft: 10, color: colors.primary1.main }}
            onClick={() => setShowPopup(POPUP_TYPES.UPDATE_LICENSES)}
          >
            Change number of licenses
          </Link>
          <Text as="p" style={{ fontSize: 16, paddingLeft: 10, color: colors.neutrals['70'] }}>
            You are using {stripeInfo.activeUsers} / {qty} licenses
          </Text>
          {pendingChange && <Text as="p" style={{ fontFamily: themeOptions.fontFamilies.regular, fontSize: 18, paddingLeft: 10, color: colors.neutrals['90'] }}>
            You have {stripeInfo.quantity} licenses, changing to {pendingChange.quantity} on {moment(pendingChange.charge_date).format('MMM DD, YYYY')}
          </Text>}
        </> : <>
          <Text as="p" style={{ fontWeight: 'normal', fontSize: 18, paddingLeft: 10, color: colors.errors.main }}>
            {stripeError}
          </Text>
        </>}
      </Col>}
    </Row>
  </>);
};

export default React.memo(TableFooter);
